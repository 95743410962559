<template>
  <div class="join">
    <div>
      <img
        class="hidden-sm-and-down"
        style="width: 100%"
        src="../../assets/mock/cooperate/组 7474.png"
        alt=""
      />
      <img
        class="hidden-md-and-up"
        style="width: 100%"
        src="../../assets/mock/us/组 12945.png"
        alt=""
      />
    </div>
    <div class="container warp">
      <div class="top">
        <h3>市场数据分析及行业需求发展</h3>
        <span></span>
        <p>Market data analysis and industry demand development</p>
      </div>
      <div class="bazaar">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="10">
            <div class="bazaar_left">
              <img
                width="100%"
                src="../../assets/mock/cooperate/矩形 2622.png"
                alt=""
              />
              <h5>中国智能锁行业发展概况</h5>
              <p>
                智能锁行业商机无限
                加盟宇起智能锁顺势而为国内智能锁市场特性为：混乱、空缺、需求量大，故此无论企业规模多大，也无法独享智能锁市场这块大蛋糕，这无疑为投资创业者留下了巨大的财富机会
                。数据来源：中国制锁行业
                信息中心《2018中国智能锁消费白皮书》《中国智能锁消费者分析报告》
              </p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="14">
            <div class="bazaar_right">
              <ul>
                <li>
                  <img src="../../assets/mock/cooperate/组 346.png" alt="" />
                  <h5><span>11000亿</span>+市场规模</h5>
                  <p>
                    据地产固定资产预估城镇住宅，酒店住宿和企业办
                    公对智能锁对潜在市场容量，高达1.1万亿
                  </p>
                </li>
                <li>
                  <img src="../../assets//mock/cooperate/组 1146.png" alt="" />
                  <h5><span>73.9%</span>销售复合增速</h5>
                  <p>
                    预计到2021年的智能门锁销量将突破3200万套，
                    2016～2020年销量复合增速达73.9%
                  </p>
                </li>
                <li>
                  <img src="../../assets/mock/cooperate/组 351.png" alt="" />
                  <h5><span>66%</span>调查用户考虑购买欲</h5>
                  <p>
                    《中国智能锁消费者分析报告》显示，66%的被调查
                    用户都表示会在未来考虑购买智能锁，指纹锁。
                  </p>
                </li>
                <li>
                  <img src="../../assets/mock/cooperate/组 352.png" alt="" />
                  <h5><span>5%</span>市场普及开发程度</h5>
                  <p>
                    中国制锁行业信息中心调研显示日本智能锁普及率约50%
                    韩国达到80%，而我国目前不足5%
                  </p>
                </li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 图表 -->
      <div style="display: flex; overflow: hidden">
        <img src="../../assets/mock/cooperate/组 7420.png" alt="" />
        <img
          style="width: 70%"
          class="hidden-sm-and-down"
          src="../../assets/mock/cooperate/组 7419.png"
          alt=""
        />
      </div>
      <!-- league -->
      <div
        style="
          background-color: #f8f8f8;
          margin: 1.25rem 0;
          padding: 0.625rem 0;
        "
      >
        <div class="top">
          <h3>加盟八大优势</h3>
          <span></span>
          <p>Eight advantages of joining</p>
        </div>
        <div class="league">
          <ul>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2624.png" alt="" />
              <h3>利润支持</h3>
              <p>我们提供丰富的利润空间让您轻松做生意</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2625.png" alt="" />
              <h3>开业支持</h3>
              <p>宣传资料（海报，易拉宝，开业传单）礼品、30天回单优惠等</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2626.png" alt="" />
              <h3>返点支持</h3>
              <p>销售返点奖励，全力支持优质经销售/加盟商。</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2627.png" alt="" />
              <h3>安装支持</h3>
              <p>开宇起专卖店均可享受专业的安装技术指导等服务。</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2628.png" alt="" />
              <h3>市场保护</h3>
              <p>严格区域保护机制，一二线城市最多2家，三四线城市1家。</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2629.png" alt="" />
              <h3>全程培训</h3>
              <p>定期或按合伙人需求，提供产品技术、销售、管理、服务等培训。</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2630.png" alt="" />
              <h3>广告支持</h3>
              <p>每年投入大量广告预算，实现地毯式综合宣传。</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/矩形 2631.png" alt="" />
              <h3>无需压货</h3>
              <p>
                依经销商实际经营情况自行决定订货量，减轻资金压力，无压货风险。
              </p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 保障 -->
      <div class="safeguard">
        <div class="top">
          <h3>五大加盟保障</h3>
          <span></span>
          <p>Five alliance guarantees</p>
        </div>
        <div class="foot">
          <ul>
            <li>
              <img src="../../assets/mock/cooperate/组 1149.png" alt="" />
              <h5>三包服务</h5>
              <p>七天无理由包退，一月包换，一年保修</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1150.png" alt="" />
              <h5>交货期准</h5>
              <p>供货保障厂家自主研发生产保障产品交期</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1151.png" alt="" />
              <h5>利润更高</h5>
              <p>让利给经销/加盟商更高的利益保障</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1152.png" alt="" />
              <h5>响应更快</h5>
              <p>我们有专业的售后团队快速响应服务</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1153.png" alt="" />
              <h5>方便退出</h5>
              <p>退出机制保障提前申请交接完成即可退出</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 加盟条件 -->
    <div class="condition safeguard">
      <div class="warp">
        <div class="top">
          <h3>加盟条件</h3>
          <span></span>
          <p>Join Condition</p>
        </div>
        <div class="foot">
          <ul>
            <li>
              <img src="../../assets/mock/cooperate/路径 2594.png" alt="" />
              <p>具有独立民事责任能力的法人或自然人</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/路径 2608.png" alt="" />
              <p>认同宇起的发展理念</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1123.png" alt="" />
              <p>具备一定的市场了解与运作经验</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1124.png" alt="" />
              <p>具备一定的资金基础投资按市场级别设定</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1125.png" alt="" />
              <p>能够启动市场运作，能组建销售、安装售后服务团队</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div>
      <div class="top">
        <h3>联系我们</h3>
        <span></span>
        <p>contact us</p>
      </div>
      <div
        class="warp hidden-sm-and-down"
        style="margin-bottom: 3.125rem; position: relative"
      >
        <img
          style="width: 100%"
          src="../../assets/mock/cooperate/2054.jpg"
          alt=""
        />
        <div class="title">
          <ul>
            <li>
              <img src="../../assets/mock/cooperate/组 1106.png" alt="" />
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1091.png" alt="" />
              <p>电话</p>
              <p>021-55220166</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1089.png" alt="" />
              <p>邮箱</p>
              <p>yuqi@yuqidata.com</p>
            </li>
            <li>
              <img src="../../assets/mock/cooperate/组 1087.png" alt="" />
              <p>地址</p>
              <p>上海市黄浦区徐家汇路 610号3201室（日月光办公楼）</p>
            </li>
          </ul>
        </div>
        <h2 style="display: block; font-size: 1.875vw; color: #9e9e9e">
          SMART LIFE IS ONE STEP CLOSER
        </h2>
        <h2 style="font-size: 1.4583vw">智能生活 更近一步</h2>
      </div>
      <div class="warp hidden-md-and-up">
        <p>电话：<span>021-55220166</span></p>
        <p>邮箱：<span>yuqi@yuqidata.com</span></p>
        <p>
          地址: <span>上海市黄浦区徐家汇路 610号3201室（日月光办公楼）</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  font-size: 0.75rem;
  margin: 0.625rem 0.9375rem;
}
.top {
  text-align: center;
  h3 {
    margin-top: 1.25rem;
  }
  span {
    width: 1.25rem;
    height: 3px;
    display: inline-block;
    background-color: #3cb59e;
  }
}
.container {
  .bazaar {
    .bazaar_left {
      box-shadow: 0px 0px 6px rgba(163, 163, 163, 0.15);
      h5 {
        margin-left: 0.9375rem;
      }
    }
    .bazaar_right {
      ul {
        display: flex;
        flex-flow: wrap;
        li {
          width: 50%;
          text-align: center;
          box-shadow: 0px 0px 6px rgba(163, 163, 163, 0.15);
          padding: 1.6667vw 0;
        }
      }
    }
  }
  .league {
    ul {
      display: flex;
      flex-flow: wrap;
      justify-content: space-evenly;
      overflow: hidden;
      li {
        text-align: center;
        width: 17.0625rem;
        img {
          margin: 1.25rem 0;
          transition: all 0.2s;
        }
        img:hover {
          transform: scale(1.1);
        }
        h3 {
          font-size: 1rem;
        }
      }
    }
  }
  .safeguard {
    overflow: hidden;
    margin: 7vw auto;
    .foot {
      overflow-x: auto;
      ul {
        display: flex;
        justify-content: center;
        min-width: 43.75rem;
        margin-top: 0.825rem;
        li {
          text-align: center;
          h5 {
            margin: 0.625rem auto;
          }
          img {
            width: 3.6458vw;
            min-width: 2.375rem;
          }
        }
      }
    }
  }
}
.condition {
  background-image: url("../../assets/mock/cooperate/组\ 1154.png");
  overflow: hidden;
  // margin: 3.125rem auto;
  padding: 4vw 0;
  .top {
    color: #fff;
  }
  .foot {
    margin-top: 4vw;
    overflow-x: auto;
    ul {
      display: flex;
      justify-content: center;
      min-width: 46.75rem;
      margin-top: 0.825rem;
      li {
        text-align: center;
        color: #fff;
        padding: 0 1.25rem;
        h5 {
          margin: 0.625rem auto;
        }
        img {
          width: 3.6458vw;
          height: 3.6vw;
          min-height: 2.375rem;
          min-width: 2.375rem;
        }
      }
    }
  }
}
.title {
  width: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
  ul {
    display: flex;
    background-color: #fff;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    li {
    }
  }
}
</style>
